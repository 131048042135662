import React, { useState, useEffect } from 'react';
import { LineChart, Area, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Bar } from 'recharts';
import { DateTime, Duration } from 'luxon';
import { exportMetricsToCsv } from "../../utils";

// Define types for your data
interface DataItem {
  date: string;
  treatment: { total: number };
  mobility: { average: number };
  pain: { average: number };
  recovery: number;
}

interface MetricsGraphProps {
  userData: DataItem[];
  groupData: DataItem[];
  userName: string | undefined;
  groupId: string | null | undefined;
  userEmail: string | null | undefined;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

interface ReadoutData {
  treatment: {hours: number | null, minutes: number | null};
  mobility: {value: number, trend: number};
  pain: {value: number, trend: number};
  recovery: {value: number, trend: number};
}

// Custom Tooltip Component
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', fontSize: '12px' }}>
        <p>Date: {data.date}</p>
        <p>Treatment: {data.treatmentTotal} minutes</p>
        <p>Mobility: {Math.round(data.mobilityAverage)}</p>
        <p>Recovery: {Math.round(data.recovery)}</p>
        <p>Pain: {Math.round(data.painAverage)}</p>
      </div>
    );
  }

  return null;
};

const initialLineVisibility = [true, true, true, true];
const timeRanges = [
  { label: '7 Days', value: 7 },
  { label: '1 Month', value: 30 },
  { label: '3 Months', value: 90 },
  { label: 'All Time', value: Infinity }, // New option
];

// Function to generate fake data
const generateFakeData = (days: number): DataItem[] => {
  const fakeData: DataItem[] = [];
  for (let i = 0; i < days; i++) {
    const date = DateTime.now().minus({ days: days - i }).toFormat('dd/MM/yyyy');
    fakeData.push({
      date,
      treatment: { total: Math.floor(Math.random() * 100) },
      mobility: { average: Math.floor(Math.random() * 10) + 1 },
      pain: { average: Math.floor(Math.random() * 10) + 1 },
      recovery: Math.floor(Math.random() * 10) + 1,
    });
  }
  return fakeData;
};

const MetricsGraph: React.FC<MetricsGraphProps> = ({ userData, groupData, userName, groupId, userEmail}) => {
  const [lineVisibility, setLineVisibility] = useState<boolean[]>(initialLineVisibility);
  const [daysShown, setDaysShown] = useState<number>(7);
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  const [useFakeData, setUseFakeData] = useState<boolean>(false);
  const [useGroupData, setUseGroupData] = useState<boolean>(false); // New state for toggling between group and user data
  const [readoutData, setReadoutData] = useState<ReadoutData | null>(null)
  const [domainStart, setDomainStart] = useState<number>(0);
  const [domainEnd, setDomainEnd] = useState<number>(0);
  
  const toggleLineVisibility = (index: number) => {
    const newVisibility = [...lineVisibility];
    newVisibility[index] = !newVisibility[index];
    setLineVisibility(newVisibility);
  };

  const handleTimeRangeChange = (days: number) => {
    if (days === Infinity) {
      const firstDate = userData.length
        ? DateTime.fromFormat(userData[0].date, 'dd/MM/yyyy')
        : DateTime.now();
      const lastDate = DateTime.now();
  
      setFilteredData(userData); // Show all data
      setDaysShown(days);
      setDomainStart(firstDate.startOf('day').toMillis());
      setDomainEnd(lastDate.endOf('day').toMillis());
    } else {
      setDaysShown(days);
    }
  };

  
  useEffect(() => {
    const sourceData = useFakeData
      ? generateFakeData(90)
      : useGroupData
      ? groupData
      : userData;
  
    if (daysShown === Infinity) {
      setFilteredData(sourceData);
    } else {
      const now = DateTime.now();
      const filtered = sourceData.filter(item => {
        const itemDate = DateTime.fromFormat(item.date, 'dd/MM/yyyy');
        return itemDate >= now.minus({ days: daysShown });
      });
      setFilteredData(filtered);
    }
  
    let t = 0, m = 0, r = 0, p = 0;
    let prevMobility = 0, prevRecovery = 0, prevPain = 0;
    let mobilityTrend = 0, recoveryTrend = 0, painTrend = 0;
  
    for (let i = 0; i < filteredData.length; i++) {
      const { treatment, mobility, recovery, pain } = filteredData[i];
      t += treatment.total;
      m += mobility.average;
      r += recovery;
      p += pain.average;
  
      if (i > 0) {
        mobilityTrend += mobility.average - prevMobility;
        recoveryTrend += recovery - prevRecovery;
        painTrend += pain.average - prevPain;
      }
  
      prevMobility = mobility.average;
      prevRecovery = recovery;
      prevPain = pain.average;
    }
  
    const length = filteredData.length;
    if (length > 0) {
      m /= length;
      r /= length;
      p /= length;
    }
  
    const hours = Math.floor(t / 60);
    const mins = t % 60;
  
    setReadoutData({
      treatment: { hours, minutes: mins },
      mobility: { value: Number(m.toFixed(2)), trend: Number(mobilityTrend.toFixed(2)) },
      recovery: { value: Number(r.toFixed(2)), trend: Number(recoveryTrend.toFixed(2)) },
      pain: { value: Number(p.toFixed(2)), trend: Number(painTrend.toFixed(2)) },
    });
  }, [userData, groupData, daysShown, useFakeData, useGroupData, filteredData]);
  
  const transformedData = filteredData.map(item => ({
    ...item,
    treatmentTotal: item.treatment.total,
    mobilityAverage: item.mobility.average,
    painAverage: item.pain.average,
    timeStamp: DateTime.fromFormat(item.date, 'dd/MM/yyyy').toMillis(),
  }));

  const processTicks = (days: number, sourceData: DataItem[]) => {
    if (days === Infinity) {
      // Generate ticks dynamically from data range
      const start = DateTime.fromFormat(sourceData[0]?.date, 'dd/MM/yyyy').startOf('month');
      const end = DateTime.now().endOf('month');
      const ticks: number[] = [];
      let current = start;
  
      while (current <= end) {
        ticks.push(current.toMillis());
        current = current.plus({ months: 1 });
      }
      return ticks;
    }

    if (days === 7) {
      return Array.from({ length: 7 }).map((_, i) =>
        DateTime.now().minus({ days: 7 - i - 1 }).startOf('day').toMillis()
      );
    } else if (days === 30) {
      return Array.from({ length: 31 }).map((_, i) =>
        DateTime.now().minus({ days: 31 - i - 1 }).startOf('day').toMillis()
      );
    } else if (days === 90) {
      return Array.from({ length: 13 }).map((_, i) =>
        DateTime.now().minus({ weeks: 13 - i - 1 }).startOf('week').toMillis()
      );
    }
  
    return [];
  };

const domainStart_m =
  daysShown === Infinity
    ? filteredData.length
      ? DateTime.fromFormat(filteredData[0]?.date, 'dd/MM/yyyy').toMillis()
      : DateTime.now().toMillis()
    : DateTime.now().minus({ days: daysShown }).startOf('day').toMillis();

const ticks = processTicks(daysShown, useGroupData ? groupData : userData);

const domainEnd_m = DateTime.now().endOf('day').toMillis();
  const formatTick = (tick: number) => {
    if (daysShown === 7) return DateTime.fromMillis(tick).toFormat('EEE');
    if (daysShown === 30) return DateTime.fromMillis(tick).toFormat('dd');
    if (daysShown === 90) return DateTime.fromMillis(tick).toFormat('MMM dd');
    return DateTime.fromMillis(tick).toFormat('dd/MM/yyyy');
  };

  return (
    <div className="card">
      <h2 className="text-xl font-semibold p-4 text-center">
        Trends for {useGroupData ? groupId : userName}
      </h2>
      
      <div className="flex mb-4" style={{paddingLeft:'350px'}}>
        <span>User Data</span>
        <label className="switch mx-4">
          {groupId !== null && (
            <input 
              type="checkbox" 
              checked={useGroupData} 
              onChange={() => setUseGroupData(!useGroupData)} 
            />
          )}
          <span className="slider round"></span>
        </label>
        <span>Group Data</span>
        {useGroupData ? (
      <span className="text-blue-500 cursor-pointer underline" style={{fontSize: '12px', paddingLeft: '20px'}}
        onClick={() => { exportMetricsToCsv("Group Metrics.csv", groupData as any[], groupId!) }} // Export all treatment data
      >
        Export all to CSV ({groupId})
      </span>) : (
        <span className="text-blue-500 cursor-pointer underline " style={{fontSize: '12px', paddingLeft:'20px'}}
        onClick={() => { exportMetricsToCsv("User Metrics.csv", userData as any[], userEmail!) }} // Export all treatment data
      >
        Export all to CSV ({userEmail})
      </span>
      )}
      </div>

      <div className="flex flex-row">
        <div className="flex-grow">
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              data={transformedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                ticks={ticks}
                tickFormatter={formatTick}
                dataKey="timeStamp"
                type="number"
                domain={[domainStart_m, domainEnd_m]}
                scale="time"
                style={{ fontSize: '12px' }}
              />
              <YAxis
                domain={[0, 10]}
                style={{ fontSize: '12px' }}
                ticks={[0, 10]}
                axisLine={{ stroke: '#FFFFFF' }}
                tickLine={{ stroke: '#FFFFFF' }}
                tick={{ fill: '#000000' }}
              />
              <YAxis
                yAxisId="t"
                domain={useGroupData ? ([0, 1000]) : [0, 100]}
                ticks={[]}
                axisLine={{ stroke: '#FFFFFF' }}
                tickLine={{ stroke: '#FFFFFF' }}
                tick={{ fill: '#FFFFFF' }}
              />
              {lineVisibility[0] && <Bar yAxisId="t" dataKey="treatmentTotal" barSize={8} fill="#9747ff" isAnimationActive={false}/>}
              {lineVisibility[1] && (
                <>
                  <Area type="monotone" dataKey="mobilityAverage" stroke="#56c1ca" fill="rgba(86, 193, 202, 0)" dot={{ r: 0 }} isAnimationActive={false} />
                  <Line type="monotone" strokeWidth="2" dataKey="mobilityAverage" stroke="#56c1ca" dot={{ r: 0 }} isAnimationActive={false} />
                </>
              )}
              {lineVisibility[2] && (
                <>
                  <Area type="monotone" dataKey="recovery" stroke="#73E38C" fill="rgba(115, 227, 140, 0.1)" dot={{ r: 0 }} isAnimationActive={false} />
                  <Line type="monotone" strokeWidth="2" dataKey="recovery" stroke="#73E38C" dot={{ r: 0 }} isAnimationActive={false}/>
                </>
              )}
              {lineVisibility[3] && (
                <>
                  <Area type="monotone" dataKey="painAverage" stroke="#F60E38" fill="rgba(255, 14, 56, 0.1)" dot={{ r: 0 }} isAnimationActive={false} />
                  <Line type="monotone" strokeWidth="2" dataKey="painAverage" stroke="#F60E38" dot={{ r: 0 }} isAnimationActive={false} />
                </>
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <h2 className="text-m font-semibold p-4 text-center">
  <div className="flex justify-center space-x-8">
    <div className="metric">
      <span className="font-medium">Total Treatment:</span> 
      <span className="text-[#9747ff]"> {readoutData?.treatment.hours}:{readoutData?.treatment.minutes}</span>
    </div>
    <div className="metric">
      <span className="font-medium">Average Mobility:</span> 
      <span className="text-[#56c1ca]"> {readoutData?.mobility?.value}</span>
      <span 
        className={`text-sm ml-2 ${
          readoutData?.recovery?.trend === 0 ? 'text-gray-400' : (readoutData?.mobility?.trend ?? 0) >= 0 ? 'text-green-500' : 'text-red-500'
        }`}
      >
        {readoutData?.recovery?.trend === 0 ? '-' : (readoutData?.mobility?.trend ?? 0) >= 0 ? '▲' : '▼'} {Math.abs(readoutData?.mobility?.trend ?? 0)}
      </span>
    </div>
    <div className="metric">
      <span className="font-medium">Average Pain:</span> 
      <span className="text-[#F60E38]"> {readoutData?.pain?.value}</span>
      <span 
        className={`text-sm ml-2 ${
          readoutData?.recovery?.trend === 0 ? 'text-gray-400' : (readoutData?.pain?.trend ?? 0) >= 0 ? 'text-red-500' : 'text-green-500'
        }`}
      >
        {readoutData?.recovery?.trend === 0 ? '-' : (readoutData?.pain?.trend ?? 0) >= 0 ? '▲' : '▼'} {Math.abs(readoutData?.pain?.trend ?? 0)}
      </span>
    </div>
    <div className="metric">
      <span className="font-medium">Average Recovery:</span> 
      <span className="text-[#73E38C]"> {readoutData?.recovery?.value}</span>
      <span 
        className={`text-sm ml-2 ${
          readoutData?.recovery?.trend === 0 ? 'text-gray-400' : (readoutData?.recovery?.trend ?? 0) > 0 ? 'text-green-500' : 'text-red-500'
          
        }`}
      >
        {
          readoutData?.recovery?.trend === 0 ? '-' : (readoutData?.recovery?.trend ?? 0) > 0 ? '▲' : '▼'} {Math.abs(readoutData?.recovery?.trend ?? 0)}
      </span>
    </div>
  </div>
</h2>
      <div className="flex justify-center my-4">
        {timeRanges.map((range) => (
          <button
            key={range.value}
            className={`btn mx-2 p-2 w-40  ${daysShown === range.value ? 'btn-active' : 'btn-inactive'}`}
            onClick={() => handleTimeRangeChange(range.value)}
          >
            {range.label}
          </button>
        ))}
      </div>
      <ul className="menu menu-vertical lg:menu-horizontal bg-base-100 rounded-box justify-center">
        <li><a className="bg-base-100 btn" style={{ color: '#9747ff', opacity: lineVisibility[0] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(0)}>Treatment</a></li>
        <li><a className="bg-base-100 btn" style={{ color: '#56c1ca', opacity: lineVisibility[1] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(1)}>Mobility</a></li>
        <li><a className="bg-base-100 btn" style={{ color: '#73E38C', opacity: lineVisibility[2] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(2)}>Recovery</a></li>
        <li><a className="bg-base-100 btn" style={{ color: '#F60E38', opacity: lineVisibility[3] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(3)}>Pain</a></li>
      </ul>
    </div>
  );
};

export default MetricsGraph;