import { createContext, useContext, useEffect, useState, useRef } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, UserProfile } from "../context/auth";
import { collection, getDocs, query, doc, updateDoc, deleteDoc, setDoc} from "firebase/firestore";
import { db } from "../firebase";
import './styles.css';
import Sequence from '../components/sequences/sequences';
import Group from '../components/group/group';
import Notifications from "../components/notifications/notifications";
import World from "../components/world/world";
import Assessments from "../components/assessments/assessments";

import { useUserLevel, AdminLevel } from "../admin";
import 'react-tooltip/dist/react-tooltip.css';

async function renameCollection() {
    try {
        // Reference to the old and new collections
        const oldCollection = collection(db, "assessment");
        const snapshot = await getDocs(oldCollection);

        if (snapshot.empty) {
            console.log("No documents found in the old collection.");
            return;
        }

        // Loop through each document in the old collection
        for (const document of snapshot.docs) {
            const data = document.data();
            const newDocRef = doc(db, "back_assessment", document.id);

            // Copy document to the new collection
            await setDoc(newDocRef, data);
            console.log(`Copied document ID: ${document.id} to back_assessment`);

            // Delete the original document from the old collection
            const oldDocRef = doc(db, "assessment", document.id);
            await deleteDoc(oldDocRef);
            console.log(`Deleted document ID: ${document.id} from assessment`);
        }

        console.log("Collection rename operation completed.");
    } catch (error) {
        console.error("Error during renaming collection: ", error);
    }
}

renameCollection();

const getUsers = async (uid: string) => {
  const docT = await getDocs(collection(db, 'users'));
  return docT.docs.map(doc => ({ ...doc.data(), id: doc.id } as UserProfile));
}

export default function Root() {
  const { currentUserLevel, setCurrentUserLevel } = useUserLevel();
  const { currentUser, signOut } = useContext(AuthContext);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [currentUserProfile, setCurrentUserProfile] = useState<UserProfile | null>(null); // Define currentUserProfile state
  const [searchQuery, setSearchQuery] = useState('');
  const [groupId, setGroupId] = useState('');
  const [allGroups, setAllGroups] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isUserDropDownOpen, setIsUserDropDownOpen] = useState(false);
  const [isGroupDropDownOpen, setIsGroupDropDownOpen] = useState(false);
  const [isGroupMember, setIsGroupMember] = useState(false);

  const userDropdownRef = useRef<HTMLDivElement>(null);
  const groupDropdownRef = useRef<HTMLDivElement>(null);
  const [isAssessmentsVisible, setIsAssessmentsVisible] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      navigate('/login', { state: { from: location.pathname + location.search } });
    }
  }, [currentUser, navigate, location.pathname, location.search]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (userDropdownRef.current && !userDropdownRef.current.contains(event.target as Node)) {
        setIsUserDropDownOpen(false);
      }
      if (groupDropdownRef.current && !groupDropdownRef.current.contains(event.target as Node)) {
        setIsGroupDropDownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkUserLevel = async () => {
      if (currentUser) {
        // Fetch all groups
        const q = query(collection(db, "groups"));
        const querySnapshot = await getDocs(q);
  
        let isSuperAdmin = false;
        let isAdmin = false;
  
        for (const doc of querySnapshot.docs) {
          const groupData = doc.data();
  
          // Check if the current user's email matches the Admin string or is a member of 'Myovolt'
          if (groupData.groupId === 'Myovolt') {
            if (groupData.Admin === currentUser.email || groupData.Members.includes(currentUser.email)) {
              setIsGroupMember(true);
              console.log('Access: Super_Admin');
              setCurrentUserLevel(AdminLevel.SUPER_ADMIN);
              isSuperAdmin = true;
              break;
            }
          } else if (!isSuperAdmin && groupData.Admin === currentUser.email) {
            // If not already Super Admin, check for Admin privileges
            setIsGroupMember(true);
            console.log('Access: Admin');
            setCurrentUserLevel(AdminLevel.ADMIN);
            isAdmin = true;
          }
  
          // Check if the current user is a member of other groups
          if (groupData.Members.includes(currentUser.email)) {
            setIsGroupMember(true);
          } else {
            setIsGroupMember(false);
          }
        }
  
        if (!isSuperAdmin && !isAdmin) {
          console.log('Access: User');
          setCurrentUserLevel(AdminLevel.USER);
        }
  
        // Fetch the users after determining the user level
        const users = await getUsers(currentUser.uid);
        setUsers(users);
        const uniqueGroupIds = [...new Set(users.map(user => user.groupId).filter(level => level !== null))];
        setAllGroups(uniqueGroupIds as string[]);
  
        const currentUserProfile = users.find(user => user.id === currentUser.uid);
        if (currentUserProfile) {
          setCurrentUserProfile(currentUserProfile); // Set currentUserProfile state
        }
  
        if (isAdmin && currentUserProfile && currentUserProfile.groupId) {
          setGroupId(currentUserProfile.groupId); // Automatically set the group filter
        }
      }
    };
  
    checkUserLevel();
  }, [currentUser, setCurrentUserLevel]);

  const handleUserSelect = (userId: string) => {
    setSelectedUserId(userId);
    navigate(`/user/${userId}`);
    setIsUserDropDownOpen(false);
    setSearchQuery('');
  };

  const handleGroupSelect = (groupId: string) => {
    setGroupId(groupId);
    setIsGroupDropDownOpen(false);
  };

  const handleCancel = () => {
    setIsAssessmentsVisible(false);
  };

  const toggleAssessmentsVisibility = () => {
    setIsAssessmentsVisible((prev) => !prev);
  };

  const filteredUsers = users.filter(user =>
    (user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.id.toLowerCase().includes(searchQuery.toLowerCase())) &&
    (!groupId || user.groupId?.toLowerCase().includes(groupId.toLowerCase()))
  );

  if (!currentUser || !currentUser.email) {
    return <div></div>;
  }

  return (
    <div className="drawer lg:drawer-open">
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <div className="navbar bg-base-100 shadow-md relative z-10">
        <div className="navbar-start p-2">
            <Group id={currentUser.uid} isGroupMember={isGroupMember} email={currentUser.email} groupId={currentUserProfile?.groupId} />
            {currentUserLevel !== AdminLevel.USER && (<Sequence users={filteredUsers} uid={currentUser.uid} />)}
            {currentUserLevel === AdminLevel.SUPER_ADMIN && (<World tempData={[]} />)}
            {currentUserLevel === AdminLevel.SUPER_ADMIN && (
              <button onClick={toggleAssessmentsVisibility} className="btn">
                Knee & Arm
              </button>
            )}
            {isAssessmentsVisible && (
              <Assessments onClose={handleCancel} />
            )}
          </div>
          <div className="navbar-center">
            <a className="btn btn-ghost text-xl"
              onClick={() => window.location.href = 'https://myovolt.com/?srsltid=AfmBOooSVjLyQ81HWmDsM0xlaYlME4i-HQuRpNz8cd-NwOgoXQWxVV-Z'}
            >Myovolt
            </a>
          </div>
          <div className="navbar-end flex">
            {currentUserLevel !== AdminLevel.USER && (
              <div className="relative" ref={userDropdownRef}>
                <input
                  type="text"
                  placeholder="Search users"
                  className="input input-bordered"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setIsUserDropDownOpen(true)}
                />
                {isUserDropDownOpen && (
                  <ul className="absolute left-0 right-0 mt-2 p-2 shadow bg-base-100 rounded-box w-full z-50 max-h-60 overflow-y-auto">
                    {filteredUsers.length === 0 && <li className="p-2">No users found</li>}
                    {filteredUsers.map((user) => (
                      <li
                        key={user.id}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleUserSelect(user.id)}
                      >
                        {user.name} <span style={{ fontSize: '12px', fontStyle: 'italic' }}>{user.email}</span>
                        <br />
                        <span style={{ fontSize: '12px', fontStyle: 'italic' }}>Group: {user.groupId}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            {currentUserLevel !== AdminLevel.USER && (
              <div className="relative ml-4" ref={groupDropdownRef}>
                <input
                  type="text"
                  placeholder="Filter by group"
                  className="input input-bordered"
                  value={groupId}
                  onChange={(e) => setGroupId(e.target.value)}
                  onFocus={() => setIsGroupDropDownOpen(true)}
                  disabled={currentUserLevel === AdminLevel.ADMIN ? true : false}
                />
                {isGroupDropDownOpen && (
                  <ul className="absolute left-0 right-0 mt-2 p-2 shadow bg-base-100 rounded-box w-full z-50 max-h-60 overflow-y-auto">
                    {allGroups.length === 0 && <li className="p-2">No group IDs found</li>}
                    {allGroups.map((group, index) => (
                      <li
                        key={index}
                        className="p-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleGroupSelect(group)}
                      >
                        {group}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            <div className="dropdown ml-4">
              <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
                </svg>
              </div>
              <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[50] p-2 shadow bg-base-100 rounded-box w-52 transform -translate-x-1/2">
                <li><a>About</a></li>
                <li><a onClick={signOut}>Logout</a></li>
              </ul>
            </div>
            <Notifications uid={currentUser.uid} />
          </div>
        </div>
        <div className="flex-grow">
          <Outlet context={{ selectedUserId }} />
        </div>
        <footer
          className="footer p-5 text-white w-full"
          style={{
            boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
            position: "fixed",
            left: 0,
            bottom: 0,
            backgroundColor: "#ffffff",
          }}
        >
          <aside>
            <h6 className="footer-title">Myovolt</h6>
          </aside>
          <nav>
            <h6 className="footer-title">Social</h6>
            <div className="grid grid-flow-col gap-4">
              <a>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
                </svg>
              </a>
              <a>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                  <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                </svg>
              </a>
              <a>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                  <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path>
                </svg>
              </a>
            </div>
          </nav>
        </footer>
      </div>
    </div>
  );
}
