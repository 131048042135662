import React, { useState, useEffect, act } from 'react';
import { Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import { DateTime } from 'luxon';
import { exportAssessmentsToCsv } from "../../utils";
import { Colors } from 'chart.js';

interface DataItem {
  id: string;
  startTime: DateTime | null;
  deviceId: string;
  flexibilityScore1: number;
  flexibilityScore2: number;
  flexibilityScore3: number;
  flexibilityScore4: number;
  flexibilityScore5: number;
  flexibilityScore6: number;
}

interface AssessmentGraphProps {
  backData: DataItem[];
  kneeData: DataItem[];
  armData: DataItem[];

  userEmail: string | null | undefined;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', fontSize: '12px' }}>
        <p>Date: {data.startTime?.toFormat('dd/MM/yyyy HH:mm')}</p>
        <p>Flexion: {data.flexibilityScore1}°</p>
        <p>Extension: {data.flexibilityScore2}°</p>
        <p>Left Rotation: {data.flexibilityScore3}°</p>
        <p>Right Rotation: {data.flexibilityScore4}°</p>
        <p>Left Lateral: {data.flexibilityScore5}°</p>
        <p>Right Lateral: {data.flexibilityScore6}°</p>
      </div>
    );
  }

  return null;
};

const initialLineVisibility = [true, true, true, true, true, true];

const AssessmentGraph: React.FC<AssessmentGraphProps> = ({ backData, kneeData, armData, userEmail }) => {
  const [lineVisibility, setLineVisibility] = useState<boolean[]>(initialLineVisibility);
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  const [activeTab, setActiveTab] = useState<string>('Back');

  const [showTable, setShowTable] = useState<boolean>(false);
  const [daysShown, setDaysShown] = useState<number>(7);
  const [currentStart, setCurrentStart] = useState<DateTime>(DateTime.now().minus({ days: 6 }).startOf('day'));
  const [currentEnd, setCurrentEnd] = useState<DateTime>(DateTime.now().endOf('day'));

  const timeRanges = [
    { label: 'Single Day', value: 1 },
    { label: '7 Days', value: 7 },
    { label: '1 Month', value: 28 },
    { label: '3 Months', value: 84 },
  ];

  const toggleLineVisibility = (index: number) => {
    const newVisibility = [...lineVisibility];
    newVisibility[index] = !newVisibility[index];
    setLineVisibility(newVisibility);
  };

  const handlePrevious = () => {
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.minus(offset));
    setCurrentEnd(currentEnd.minus(offset));
  };

  const handleNext = () => {
    const offset = daysShown === 1 ? { days: 1 } : { days: daysShown };
    setCurrentStart(currentStart.plus(offset));
    setCurrentEnd(currentEnd.plus(offset));
  };

  const handleTimeRangeChange = (days: number) => {
    setDaysShown(days);
    const newStart =
      days === 1
        ? DateTime.now().startOf('day')
        : DateTime.now().minus({ days: days - 1 }).startOf('day');
    const newEnd = DateTime.now().endOf('day');
    setCurrentStart(newStart);
    setCurrentEnd(newEnd);
  };
  
  useEffect(() => {
    const filterData = (data: DataItem[]) => {
      return data.filter(item => {
        const itemDate = item.startTime;
        return itemDate && itemDate >= currentStart && itemDate <= currentEnd;
      });
    };

    let dataToFilter = backData;
    if (activeTab === 'Knee') dataToFilter = kneeData;
    else if (activeTab === 'Arm') dataToFilter = armData;

    console.log('DEBUG:', dataToFilter)
    setFilteredData(filterData(dataToFilter));
  }, [activeTab, currentStart, currentEnd, backData, kneeData, armData]);


  const transformedData = filteredData.map(item => ({
    ...item,
    timeStamp: item.startTime ? item.startTime.toMillis() : 0,
  }));

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const ticks = Array.from({ length: daysShown }).map((_, i) =>
    currentStart.plus({ days: i }).toMillis()
  );

  const formatTick = (tick: number) =>
    DateTime.fromMillis(tick).toFormat(daysShown > 7 ? "dd/MMM" : "EEE dd/MMM");

  const isNextDisabled = currentEnd >= DateTime.now().endOf("day");

  return (
    <div className="card" style={{height: '500px'}}>
      
      <div className="join">
        {["Back", "Knee", "Arm"].map((tab) => (
          <input
            key={tab}
            type="radio"
            name="tabs"
            className={`join-item btn btn-square ${activeTab === tab ? "btn-active" : ""}`}
            aria-label={tab}
            checked={activeTab === tab}
            onChange={() => handleTabChange(tab)}
          />
        ))}
      </div>
        
      <span
        className="text-blue-500 cursor-pointer underline text-center"
        style={{ fontSize: '12px' }}
        onClick={() => exportAssessmentsToCsv("assessments.csv", backData, kneeData, armData, userEmail!)} // Export all assessment data, not just the filtered
      >
        Export all to CSV
      </span>
      <div className="flex justify-between items-center mb-4 p-4">
        <button className="btn" style={{fontSize: '20px'}} onClick={handlePrevious}>←</button>
        <h2>{currentStart.toFormat('dd/MM/yyyy')} - {currentEnd.toFormat('dd/MM/yyyy')}</h2>
        <button className="btn" style={{fontSize: '20px'}} onClick={handleNext} disabled={isNextDisabled}>→</button>
        <div className="flex">
          <select
            className="dropdown-selector p-2 rounded border"
            value={daysShown}
            onChange={(e) => handleTimeRangeChange(Number(e.target.value))}
          >
            {timeRanges.map((range) => (
              <option key={range.value} value={range.value}>
                {range.label}
              </option>
            ))}
          </select>
        </div>
        <h3 style={{ position: 'relative', left: '0px', fontSize: '14px'}}> Table Display</h3>
        <label className="switch" style={{ right: '30px' }}>
          <input type="checkbox" checked={showTable} onChange={() => setShowTable(!showTable)} />
          <span className="slider"></span>
        </label>
      </div>
      {!showTable ? (
        <div style={{height: '200px'}}>
          <ResponsiveContainer>
            <ComposedChart
              data={transformedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                ticks={ticks}
                tickFormatter={formatTick}
                dataKey="timeStamp"
                type="number"
                domain={[currentStart.toMillis(), currentEnd.toMillis()]}
                scale="time"
                style={{ fontSize: '12px' }}
              />
              <YAxis domain={[0, 90]} ticks={[30, 60]} axisLine={{ stroke: '#AAAAAA' }} tickLine={{ stroke: '#AAAAAA' }} tick={{ fill: '#000000', fontSize: '12px' }} />
              {lineVisibility[0] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore1" stroke="#4880B8" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[1] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore2" stroke="#73E38C" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[2] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore3" stroke="#9747ff" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[3] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore4" stroke="#56c1ca" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[4] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore5" stroke="#F60E38" dot={{ r: 2 }} isAnimationActive={false}/>}
              {lineVisibility[5] && <Line type="monotone" strokeWidth="1.5" dataKey="flexibilityScore6" stroke="#FFA452" dot={{ r: 2 }} isAnimationActive={false}/>}
            </ComposedChart>
          </ResponsiveContainer>
          <ul className="menu menu-horizontal bg-base-100 rounded-box">
          {activeTab === 'Back' && (
            <>
              <li><a
                  className="bg-base-100 btn"
                  style={{ color: '#4880B8', opacity: lineVisibility[0] ? '1' : '0.2' }}
                  onClick={() => toggleLineVisibility(0)}
                >
                  Flexion
                </a>
              </li>
              <li>
                <a
                  className="bg-base-100 btn"
                  style={{ color: '#73E38C', opacity: lineVisibility[1] ? '1' : '0.2' }}
                  onClick={() => toggleLineVisibility(1)}
                >
                  Extension
                </a>
              </li>
              <li>
                <a
                  className="bg-base-100 btn"
                  style={{ color: '#9747ff', opacity: lineVisibility[2] ? '1' : '0.2' }}
                  onClick={() => toggleLineVisibility(2)}
                >
                  Left Rotation
                </a>
              </li>
              <li>
                <a
                  className="bg-base-100 btn"
                  style={{ color: '#56c1ca', opacity: lineVisibility[3] ? '1' : '0.2' }}
                  onClick={() => toggleLineVisibility(3)}
                >
                  Right Rotation
                </a>
              </li>
              <li>
                <a
                  className="bg-base-100 btn"
                  style={{ color: '#F60E38', opacity: lineVisibility[4] ? '1' : '0.2' }}
                  onClick={() => toggleLineVisibility(4)}
                >
                  Left Lateral
                </a>
              </li>
              <li>
                <a
                  className="bg-base-100 btn"
                  style={{ color: '#FFA452', opacity: lineVisibility[5] ? '1' : '0.2' }}
                  onClick={() => toggleLineVisibility(5)}
                >
                  Right Lateral
                </a>
              </li>
            </>
          )}
          {activeTab === 'Knee' && (
            <>
              <li>
                <a
                  className="bg-base-100 btn"
                  style={{ color: '#4880B8', opacity: lineVisibility[0] ? '1' : '0.2' }}
                  onClick={() => toggleLineVisibility(0)}
                >
                  Extension
                </a>
              </li>
            </>
          )}
           {activeTab === 'Arm' && (
            <>
              <li>
                <a
                  className="bg-base-100 btn"
                  style={{ color: '#4880B8', opacity: lineVisibility[0] ? '1' : '0.2' }}
                  onClick={() => toggleLineVisibility(0)}
                >
                  Flexion
                </a>
              </li>
            </>
          )}
         </ul>
        </div>
      ) : (
        <div className="table-responsive" style={{ height: '300px' }}>
          {activeTab === 'Back' && <table className="table table-zebra" style={{ fontSize: '12px' }}>
            <thead>
              <tr>
                <th>Device</th>
                <th>Start Time</th>
                <th>Flexion</th>
                <th>Extension</th>
                <th>Left Rotation</th>
                <th>Right Rotation</th>
                <th>Left Lateral</th>
                <th>Right Lateral</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row) => (
                <tr key={row.id}>
                  <td>{row.deviceId}</td>
                  <td>{row.startTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                  <td>{row.flexibilityScore1}</td>
                  <td>{row.flexibilityScore2}</td>
                  <td>{row.flexibilityScore3}</td>
                  <td>{row.flexibilityScore4}</td>
                  <td>{row.flexibilityScore5}</td>
                  <td>{row.flexibilityScore6}</td>
                </tr>
              ))}
            </tbody>
          </table>}
          {activeTab === 'Knee' && <table className="table table-zebra" style={{ fontSize: '12px' }}>
            <thead>
              <tr>
                <th>Device</th>
                <th>Start Time</th>
                <th>Extension</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row) => (
                <tr key={row.id}>
                  <td>{row.deviceId}</td>
                  <td>{row.startTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                  <td>{row.flexibilityScore1}</td>
                </tr>
              ))}
            </tbody>
          </table>}
          {activeTab === 'Arm' && <table className="table table-zebra" style={{ fontSize: '12px' }}>
            <thead>
              <tr>
                <th>Device</th>
                <th>Start Time</th>
                <th>Flexion</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row) => (
                <tr key={row.id}>
                  <td>{row.deviceId}</td>
                  <td>{row.startTime?.toFormat('yyyy-MM-dd HH:mm')}</td>
                  <td>{row.flexibilityScore1}</td>
                </tr>
              ))}
            </tbody>
          </table>}
        </div>
      )}
    </div>
  );
  
};

export default AssessmentGraph;



